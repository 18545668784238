<template>
  <div>
    <!-- 引入的返回头组件 -->
    <titlebar :name="page_name" :is_custom_back="true" @goBackCustom="goBack" />
    <div class="desk-right">
      <div class="record">
        <div class="records">
          <div class="right-top flex flex-ac flex-jsb">
            <div class="flex" style="width: 50%">
              <div
                class="top-item flex flex-jc"
                v-for="(item, index) in tabList"
                :key="index"
                @click="selectTab(index)"
              >
                <div
                  class="color-666 ft-28 flex flex-column flex-ac tab-item"
                  :class="chooseIndex == index ? 'active-tab' : ''"
                >
                  <div class="activetab-text">
                    {{ (item.tab_name || "") }}<template v-if="index != 3">({{ item.num || 0 }})</template>
                  </div>
                </div>
              </div>
            </div>
            <div class="flex flex-ac">
              <input
                v-model="queryForm.start_time"
                class="ordernum-input search-date-input"
                readonly
                @click="showCalendarStart = true"
              />
              <span class="ft-24 flex flex-ac">&nbsp;至&nbsp;</span>
              <input
                v-model="queryForm.end_time"
                class="ordernum-input search-date-input"
                readonly
                @click="showCalendarEnd = true"
              />
              <button class="screenbtn" @click="search_table">筛 选</button>
              <button class="screenbtn" @click="reset_time">重 置</button>
            </div>
            <van-popup v-model="showCalendarStart" round position="bottom" class="popup-picker-custom">
              <van-datetime-picker
                v-model="defaultDate[0]"
                :min-date="minDate"
                :max-date="maxDate"
                type="date"
                @confirm="onConfirmTime($event, 1)"
                @cancel="showCalendarStart = false"
                item-height="8.5rem"
                :formatter="formatter"
              />
            </van-popup>
            <van-popup v-model="showCalendarEnd" round position="bottom" class="popup-picker-custom">
              <van-datetime-picker
                v-model="defaultDate[1]"
                :min-date="minDate"
                :max-date="maxDate"
                type="date"
                @confirm="onConfirmTime($event, 2)"
                @cancel="showCalendarEnd = false"
                item-height="8.5rem"
                :formatter="formatter"
              />
            </van-popup>
          </div>
        </div>
        <!-- 消费记录 -->
        <div v-if="chooseIndex == 0" style="height: calc(65vh - 7rem)">
          <table
            width="100%"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <thead>
              <tr
                bgcolor="#F2F2F2"
                class="thead-tr ft-28"
                style="height: 9rem;"
              >
                <td
                  :class="[
                    'color-333',
                    'ft-28',
                    'text-center',
                  ]"
                  v-for="(item, index) in theadSummary"
                  :key="index"
                >
                  {{ item }}
                </td>
              </tr>
            </thead>
            <tbody style="overflow-y: scroll">
              <tr
                v-for="item in contentList"
                :key="item.id"
                :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
              >
                <td>{{ item.order_num || '' }}</td>
                <td>
                  {{ item.original_price || '' }}
                </td>
                <td style="line-height: 1.3; padding: 0.55rem 0;">
                  <template v-if="item.discountPrice.length > 0">
                    <div v-for="item in item.discountPrice">{{ item }}</div>
                  </template>
                  <div v-else>0</div>
                </td>
                <td>
                  {{ item.return_money || '' }}
                </td>
                <td>{{ item.actual_price || '' }}</td>
                <td>
                  {{ item.pay_type || '' }}
                </td>
                <td>
                  {{ item.pay_time || '' }}
                </td>
              </tr>
              <div class="mg-tp30 content" v-if="contentList.length == 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="chooseIndex == 0">
          <van-pagination
            v-model="page"
            :total-items="count"
            :items-per-page="limit"
            mode="simple"
            force-ellipses
            style="background-color: #f2f2f2"
            @change="getexpensesRecord"
          />
        </div>
        <!-- 充值记录 -->
        <div v-if="chooseIndex == 1" style="height: calc(65vh - 7rem)">
          <table
            width="100%"
            style="border-collapse: collapse"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <thead>
              <tr
                bgcolor="#F2F2F2"
                class="thead-tr ft-28"
                style="height: 9rem"
              >
                <td
                  :class="[
                    'color-333',
                    'ft-28',
                    'text-center',
                  ]"
                  v-for="(item, index) in theadSummary2"
                  :key="index"
                >
                  {{ item }}
                </td>
              </tr>
            </thead>
            <tbody style="overflow-y: scroll">
              <tr
                v-for="item in rechargeList"
                :key="item.id"
                :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
              >
                <td>{{ item.order_number }}</td>
                <td>{{ item.before_amount }}</td>
                <td>{{ item.recharge_amount }}</td>
                <td>{{ item.give_amount }}</td>
                <td>{{ item.after_amount }}</td>
                <td>
                  {{ item.pay_type }}
                </td>
                <td>
                  {{ item.create_time }}
                </td>
              </tr>
              <div class="mg-tp30 content" v-if="rechargeList.length == 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="chooseIndex == 1">
          <van-pagination
            v-model="page1"
            :total-items="count1"
            :items-per-page="limit1"
            mode="simple"
            force-ellipses
            style="background-color: #f2f2f2"
            @change="getrechargeRecord"
          />
        </div>
        <!-- 调整记录 -->
        <div v-if="chooseIndex == 2" style="height: calc(65vh - 7rem)">
          <table
            width="100%"
            style="border-collapse: collapse"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <thead>
              <tr
                bgcolor="#F2F2F2"
                class="thead-tr ft-28"
                style="height: 9rem"
              >
                <td
                  :class="[
                    'color-333',
                    'ft-28',
                    'text-center',
                  ]"
                  v-for="(item, index) in theadSummary3"
                  :key="index"
                >
                  {{ item }}
                </td>
              </tr>
            </thead>
            <tbody style="overflow-y: scroll">
              <tr
                v-for="item in adjustmentList"
                :key="item.id"
                :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
              >
                <td>
                  {{ item.before_amount }}
                </td>
                <td>{{ item.after_amount }}</td>
                <td>{{ item.remark }}</td>
                <td>{{ item.staff_name }}</td>
                <td>
                  {{ item.create_time }}
                </td>
              </tr>
              <div class="mg-tp30 content" v-if="adjustmentList.length == 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="chooseIndex == 2">
          <van-pagination
            v-model="page2"
            :total-items="count2"
            :items-per-page="limit2"
            mode="simple"
            force-ellipses
            style="background-color: #f2f2f2"
            @change="getresiveRecord"
          />
        </div>
        <!-- 积分明细 -->
        <div v-if="chooseIndex == 3" style="height: calc(65vh - 7rem)">
          <table
            width="100%"
            style="border-collapse: collapse"
            border="0"
            cellspacing="0"
            cellpadding="0"
          >
            <thead>
              <tr
                bgcolor="#F2F2F2"
                class="thead-tr ft-28"
                style="height: 9rem"
              >
                <td
                  :class="[
                    'color-333',
                    'ft-28',
                    'text-center',
                  ]"
                  v-for="(item, index) in theadSummary4"
                  :key="index"
                >
                  {{ item }}
                </td>
              </tr>
            </thead>
            <tbody style="overflow-y: scroll">
              <tr
                v-for="item in integralRecordList"
                :key="item.id"
                :class="['color-333', 'ft-24', 'text-center', 'tbody-tr']"
              >
                <td>
                  {{ item.create_time }}
                </td>
                <!-- 变动类型:1消费得积分,2积分抵扣,3积分抵扣退回,4退货扣减 -->
                <td>
                  {{ item.type | ChangeType }}
                  <van-icon v-if="item.type == 5" 
                    style="vertical-align: text-top;" name="info" color="#666" size="3rem" class="mg-lt5"
                    @click="openDialog(item)"
                  />
                </td>
                <td>{{ item.front }}</td>
                <td>
                  <span v-if="item.type == 1 || item.type == 3">+</span>
                  <span v-if="item.type == 2 || item.type == 4 || item.type == 5">-</span>
                  {{ item.score }}
                </td>
                <td>
                  {{ item.after }}
                </td>
              </tr>
              <div class="mg-tp30 content" v-if="integralRecordList.length == 0">
                <div class="nullStyle">
                  <p class="text-center ft-24 color-999 mg-tp30">暂无数据~</p>
                </div>
              </div>
            </tbody>
          </table>
        </div>
        <div class="pagination" v-if="chooseIndex == 3">
          <van-pagination
            v-model="page3"
            :total-items="count3"
            :items-per-page="limit3"
            mode="simple"
            force-ellipses
            style="background-color: #f2f2f2"
            @change="getIntegralRecord"
          />
        </div>
      </div>
    </div>

    <popupOfDialog
      v-if="showDialog"
      :type="dialogType"
      :title="dialogTitle"
      :message="dialogMsg"
      :data="dialogData"
      :showCancelBtn="false"
      :showConfirmBtn="false"
      @closePopDialog="closePopDialog"
    ></popupOfDialog>

  </div>
</template>
<script>
import { formatTimeWitchLine } from "@/utils/util";
import { datetimePickerFormatter } from '../../utils/util';
import titlebar from "@/views/component/titlebar";
import popupOfDialog from '@/views/component/popupOfDialog.vue';
export default {
  components: {
    titlebar,
    popupOfDialog,
  },
  data() {
    return {
      tabList: [
        { tab_name: "消费记录", num: 0 },
        { tab_name: "充值记录", num: 0 },
        { tab_name: "调整记录", num: 0 },
        { tab_name: "积分明细", num: 0 },
      ],
      //   时间搜索
      queryForm: {
        start_time: "",
        end_time: "",
      },
      showCalendarStart: false,
      showCalendarEnd: false,
      chooseIndex: 0,
      minDate: new Date(),
      maxDate: new Date(),
      theadSummary: [
        "订单号",
        "原价",
        // "其他",
        "优惠",
        // "打折金额",
        // "抹零金额",
        "退款",
        "实收",
        "支付方式",
        "收银时间",
        // "收银员",
      ],
      contentList: [],
      theadSummary2: [
        "充值单号",
        "充值前金额",
        "充值金额",
        "赠送金额",
        "充值后金额",
        "支付方式",
        "充值时间",
        // "收银员",
      ],
      theadSummary3: ["调整前余额", "调整后余额", "备注", "操作人", "调整时间"],
      member_id: 0,
      rechargeList: [], //充值列表
      adjustmentList: [], //调整列表
      startDateTime: 0,
      endDateTime: 0,
      page: 1,
      limit: 6,
      count: 0,
      page1: 1,
      limit1: 6,
      count1: 0,
      page2: 1,
      limit2: 6,
      count2: 0,
      formatter: datetimePickerFormatter,
      defaultDate: [new Date(), new Date()],
      page_name: 'memberrecord',
      theadSummary4: ['时间', '变动类型', '变动前积分', '变动积分', '变动后积分'],
      integralRecordList: [],
      page3: 1,
      limit3: 6,
      count3: 0,
      showDialog: false,
      dialogType: '',
      dialogTitle: '',
      dialogMsg: '',
      dialogData: {},
    };
  },
  created() {
    this.member_id = this.$route.query.id;
    this.getexpensesRecord();
    this.getrechargeRecord();
    this.getresiveRecord();
    let newD = new Date().setMonth(new Date().getMonth() - 2);
    newD = new Date(newD).setHours(0);
    newD = new Date(newD).setMinutes(0);
    this.minDate = new Date(newD);
    this.maxDate = new Date();
  },
  filters: {
    payment(data) {
      if (data == 1 || data == 4) {
        return "微信";
      } else if (data == 2 || data == 5) {
        return "支付宝";
      } else if (data == 3) {
        return "会员余额";
      } else if (data == 6) {
        return "现金";
      } else if (data == 7 || data == 8) {
        return "收呗";
      }
    },
    // 变动类型:1消费得积分,2积分抵扣,3积分抵扣退回,4退货扣减
    ChangeType(val) {
      const obj = {
        1: '消费得积分',
        2: '积分抵扣',
        3: '积分抵扣退回',
        4: '退货扣减',
        5: '兑换礼品',
      }
      return obj[val] || '';
    },
  },
  methods: {
    closePopDialog() {
      this.showDialog = false;
    },
    openDialog(item) {
      this.dialogType = '4';
      this.dialogTitle = '兑换明细';
      let content = item.content;
      this.dialogMsg = `优惠券：${content[0].name}  ×1`;
      this.showDialog = true;
    },
    getIntegralRecord() {
      let data = {
        member_id: this.member_id,
        page: this.page3,
        limit: this.limit3,
      };
      if (this.queryForm.start_time != "") {
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
      }
      this.$api.scoreLog_getList(data).then(res => {
        if (res.code == 1) {
          this.integralRecordList = res.data.list;
          this.count3 = Number(res.data.count);
          this.tabList[3].num = Number(res.data.count);
          this.page3 = Number(res.data.page);
        } else {
          this.$toast(res.msg);
        }
      })
    },
    goBack() {
      this.$router.replace({
        name: 'vipManagement',
        params: {
          page: this.$route.query.page,
          content: this.$route.query.content,
          active: this.$route.query.active,
        },
      })
    },
    // 点击tab切换
    selectTab(index) {
      this.chooseIndex = index;
      this.gettable(index);
    },
    // 搜索记录列表
    search_table() {
      if (this.queryForm.start_time == "" && this.queryForm.end_time != "") {
        this.$toast("请选择开始日期");
        return;
      }
      if (this.queryForm.start_time != "" && this.queryForm.end_time == "") {
        this.$toast("请选择结束日期");
        return;
      }
      this.page = 1;
      this.page1 = 1;
      this.page2 = 1;
      this.page3 = 1;
      this.getexpensesRecord();
      this.getrechargeRecord();
      this.getresiveRecord();
      if (this.chooseIndex == 3) {
        this.getIntegralRecord();
      }
    },
    // 重置时间
    reset_time() {
      this.queryForm = {
        start_time: "",
        end_time: "",
      };
      this.defaultDate = [new Date(), new Date()];
      this.startDateTime = 0;
      this.endDateTime = 0;
      this.chooseIndex = 0;
      this.page = 1;
      this.page1 = 1;
      this.page2 = 1;
      this.page3 = 1;
      //   查看消费列表;
      this.getexpensesRecord();
      // 查看充值列表
      this.getrechargeRecord();
      // 查看调整记录列表
      this.getresiveRecord();
      if (this.chooseIndex == 3) {
        this.getIntegralRecord();
      }
    },
    // 会员消费记录列表
    getexpensesRecord() {
      let data = {
        id: this.member_id,
        page: this.page,
        limit: this.limit,
      };
      if (this.queryForm.start_time != "") {
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
      }
      this.$api.dt_expensesRecord(data).then((res) => {
        if (res.code == 1) {
          this.contentList = res.data.data.list;
          this.tabList[0].num = res.data.data.count;
          this.count = Number(res.data.data.count);
          this.page = Number(res.data.data.page);
          this.contentList.forEach(element => {
            let discountPrice = []
            if (element.discount_price > 0) {
              discountPrice.push(`会员优惠:${element.discount_price}`);
            }
            if (element.discount_rate_price > 0) {
              discountPrice.push(`打折金额:${element.discount_rate_price}`);
            }
            if (element.wipe_price > 0) {
              discountPrice.push(`抹零金额:${element.wipe_price}`);
            }
            if (element.score_discount > 0) {
              discountPrice.push(`积分抵扣:${element.score_discount}`);
            }
            if (element.coupon_price > 0) {
              discountPrice.push(`优惠券:${element.coupon_price}`);
            }
            element.discountPrice = discountPrice;
          });
        }
      });
    },
    // 会员充值记录列表
    getrechargeRecord() {
      let data = {
        id: this.member_id,
        page: this.page1,
        limit: this.limit1,
      };
      if (this.queryForm.start_time != "") {
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
      }
      this.$api.dt_rechargeRecord(data).then((res) => {
        if (res.code == 1) {
          // console.log("充值记录", res.data);
          this.rechargeList = res.data.data.list;
          this.tabList[1].num = res.data.data.count;
          this.page1 = Number(res.data.data.page);
          this.count1 = Number(res.data.data.count);
        }
      });
    },
    // 会员调整记录列表
    getresiveRecord() {
      let data = {
        id: this.member_id,
        page: this.page2,
        limit: this.limit2,
      };
      if (this.queryForm.start_time != "") {
        data.start_time = this.queryForm.start_time;
        data.end_time = this.queryForm.end_time;
      }
      this.$api.dt_resiveRecord(data).then((res) => {
        if (res.code == 1) {
          // console.log("调整记录", res.data);
          this.adjustmentList = res.data.data.list;
          this.tabList[2].num = res.data.data.count;
          this.page2 = Number(res.data.data.page);
          this.count2 = Number(res.data.data.count);
        }
      });
    },
    // 切换时调用接口
    gettable(index) {
      switch (index) {
        case 0:
          this.page = 1;
          this.getexpensesRecord();
          break;
        case 1:
          this.page1 = 1;
          this.getrechargeRecord();
          break;
        case 2:
          this.page2 = 1;
          this.getresiveRecord();
          break;
        case 3:
          this.page3 = 1;
          this.getIntegralRecord();
          break;
        default:
          break;
      }
    },
    // 日期
    onConfirmTime(event, type) {
      // console.log(event, type);
      if (type == 1) {
        if (
          this.endDateTime > 0 &&
          new Date(event).getTime() > this.endDateTime
        ) {
          this.$toast("开始日期不能大于结束日期");
          return;
        }
        this.startDateTime = new Date(event).getTime();
        this.queryForm.start_time = formatTimeWitchLine(event);
        this.showCalendarStart = false;
      } else {
        if (
          this.startDateTime > 0 &&
          new Date(event).getTime() < this.startDateTime
        ) {
          this.$toast("结束日期不能小于开始日期");
          return;
        }
        this.endDateTime = new Date(event).getTime();
        this.queryForm.end_time = formatTimeWitchLine(event);
        this.showCalendarEnd = false;
      }
      // if (this.queryForm.start_time != "" && this.queryForm.end_time != "") {
      //   this.search_table();
      // }
    },
  },
};
</script>
<style lang="less" scoped>
.width-9 {
  width: 9%;
}
.width-10 {
  width: 12%;
}
.desk-right {
  height: calc(100vh - 19.7rem);
  margin-top: 1.5rem;
  .record {
    margin-top: 1.5rem;
    height: 100%;
    background: #fff;
    border-radius: 0.8rem;
    padding: 0 2rem;
    .records {
      width: 100%;
      .right-top {
        width: 100%;
        padding: 1.5rem 0 3rem;
        height: 6rem;
        background: #fff;
        .top-item {
          width: 25%;
          //   border-right: 0.07rem solid #ccc;
        }
        .tab-item {
          padding-left: 1rem;
          padding-right: 1rem;
          .activetab-text {
            z-index: 2;
          }
        }
      }
    }
  }
  .ordernum-input {
    border: 0.2rem #DEDEDE solid;
    width: 19rem;
    height: 5.5rem;
    border-radius: 0.8rem;
    font-size: 2.6rem;
    padding-left: 1.5rem;
  }
  .search-date-input {
    width: 19rem;
    font-size: 2.6rem;
    text-align: center;
  }
  .screenbtn {
    background: #1588f5;
    font-size: 2.4rem;
    color: #fff;
    height: 5.5rem;
    padding: 0 3rem;
    border-radius: 0.8rem;
    margin: 0 0.5rem 0 1rem;
  }
}
.active-tab {
  position: relative;
  color: #333;
  font-weight: bold;
  .activetab-text {
    font-weight: bold;
  }
}
.active-tab::after {
  content: "";
  position: absolute;
  bottom: -2.5rem;
  width: 100%;
  color: #1588f5;
  border-bottom: 1.7rem solid #1588f5;
  transition: 0.3s;
  animation: 0.8s widen;
}
@keyframes widen {
  0% {
    width: 0%;
  }
  100% {
    width: 100%;
  }
}
table thead,
tbody tr,
tfoot tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

table {
  background: white;
  position: relative;
  display: table;
  // overflow-y: scroll;
  width: 100%;
  overflow-x: hidden;
  height: 100%;

  ::-webkit-scrollbar {
    display: block;
    width: 0.6rem;
    height: 0.5rem;
    background-color: #fff;
  }
  
  ::-webkit-scrollbar-thumb {
    display: block;
    min-height: 0.5rem;
    min-width: 0.4rem;
    border-radius: 0.8rem;
    background-color: rgb(217, 217, 217);
  }

  thead td {
    border: 0.2rem rgba(0, 0, 0, 0.06) solid;
  }

  tbody {
    // overflow-y: scroll;
    display: block;
    height: calc(100% - 9rem);
    width: 100%;
    overflow-x: hidden;
  }

  .tbody-tr {
    overflow-y: scroll;
    height: 9rem;
    width: 100%;

    &.odd {
      background: #fef7ef;
    }

    td {
      height: 9rem;
      border: 0.2rem rgba(0, 0, 0, 0.06) solid;
      word-break: break-all;
    }
  }
}
.pagination {
  position: absolute;
  bottom: 1.5rem;
  right: 0;
  width: 20%;
}
/deep/.van-pagination__item--active {
  background-color: #1588f5;
}
</style>